import { SellerConfigTable } from "./SellerConfigTable";
import { SellerDetails } from "./SellerDetails";
import { Box } from "@deliverr/ui";
import { Container } from "components/Container";
import { useCreditBlockDetail } from "./useCreditBlockDetail";
import { StatusHistoryTable } from "./StatusHistoryTable";

export const CreditBlockDetail = () => {
  const { toleranceAmount, setToleranceAmount, status, setStatus } = useCreditBlockDetail();
  return (
    <>
      <SellerDetails
        toleranceAmount={toleranceAmount}
        setToleranceAmount={setToleranceAmount}
        status={status}
        setStatus={setStatus}
      />
      <Box paddingTop={"S3"}>
        <Container fullWidth={true} showGoBack={false}>
          <SellerConfigTable toleranceAmount={toleranceAmount} />
        </Container>
        <Container fullWidth={true} showGoBack={false}>
          <StatusHistoryTable status={status} />
        </Container>
      </Box>
    </>
  );
};
