import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { STATUS_HISTORY_ITEMS_MAX_LIMIT } from "../constants";
import { format } from "date-fns";
import { toast } from "@deliverr/ui";
import { OrganizationStatus, OrganizationHistory } from "@deliverr/business-types";

interface useSellerHistoryTableProps {
  status: OrganizationStatus;
}

export const useSellerHistoryTable = ({ status }: useSellerHistoryTableProps) => {
  const { organizationClient } = useClientsWithAuth();
  const { sellerId } = useParams() as {
    sellerId: string;
  };

  const {
    loading,
    value: currentPageItems,
    error,
  } = useAsync(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const sellerConfigHistoryResponse = await organizationClient.getOrganizationHistory(
      sellerId,
      STATUS_HISTORY_ITEMS_MAX_LIMIT
    );
    return sellerConfigHistoryResponse.data.history;
  }, [organizationClient, sellerId, status]);

  if (error) {
    toast.error("Failed to fetch status history");
  }

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationHistory>();
    return [
      columnHelper.accessor("entityUpdatedAt", {
        header: "Date",
        cell: (info) => {
          const date = info.getValue();
          return date ? format(new Date(date), "MMM d, yyyy, h:mm:ss a") : "N/A";
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
      }),
      columnHelper.accessor("statusReason", {
        header: "Status Reason",
      }),
      columnHelper.accessor("updatedBy.email", {
        header: "Updated By",
      }),
    ];
  }, []);

  const table = useReactTable({
    data: currentPageItems ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
    loading,
    currentPageItems,
  };
};
