import { Container } from "components/Container";
import { useInboundListView } from "./useInboundListView";
import { LoadingSpinner } from "components/LoadingSpinner";
import { InboundListTable } from "./InboundListTable/InboundListTable";
import styled from "@emotion/styled";

const StyledContainer = styled(Container)`
  // Matches the top margin in the Container component
  margin-bottom: 30px;
`;

export const InboundListView = () => {
  const { loadingEntries, inboundListEntries } = useInboundListView();
  return (
    <StyledContainer fullWidth={true}>
      {loadingEntries ? <LoadingSpinner loading /> : <InboundListTable data={inboundListEntries} />}
    </StyledContainer>
  );
};
