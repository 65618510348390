import { Container } from "components/Container";
import { LoadingSpinner } from "components/LoadingSpinner";
import styled from "@emotion/styled";
import { useInboundDetailView } from "./useInboundDetailView";
import { Input, Button, Card, ThemeProps, Text, Stack } from "@deliverr/ui";
import { Controller } from "react-hook-form";
import { InboundDetailTable } from "./InboundDetailTable";

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
`;

const StyledForm = styled.form<ThemeProps>`
  ${({ theme }) => `
    display: flex;
    align-items: flex-end;
    gap: ${theme.spacing.S4};
    margin-bottom: ${theme.spacing.S4};

    .input-container {
      flex: 1;
    }
  `}
`;

const StyledCard = styled(Card)`
  margin-bottom: 24px;
`;

export const InboundDetailView = () => {
  const { loadingEntries, inboundDetailEntries, control, onSubmit } = useInboundDetailView();

  return (
    <StyledContainer fullWidth={true}>
      <StyledCard>
        <StyledForm onSubmit={onSubmit}>
          <div className="input-container">
            <Controller
              name="shipmentId"
              control={control}
              rules={{
                required: "Shipment ID is required",
                pattern: {
                  value: /^\d+$/,
                  message: "Please enter a valid number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  label="Shipment ID"
                  placeholder="Enter shipment ID"
                  type="number"
                  required
                  hasError={!!error}
                  errorMessage={error?.message}
                />
              )}
            />
          </div>
          <Button type="submit">Load Inbound Details</Button>
        </StyledForm>
      </StyledCard>

      {loadingEntries ? (
        <LoadingSpinner loading />
      ) : inboundDetailEntries.length ? (
        <Stack>
          <Text size="bodyLarge">Box Details</Text>
          <InboundDetailTable data={inboundDetailEntries} />
        </Stack>
      ) : null}
    </StyledContainer>
  );
};
