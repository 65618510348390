import { useMemo, useState } from "react";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { handleInboundVisibilityError } from "utils/handleInboundVisibilityError";
import { BoxStatus } from "@deliverr/inbound-visibility-client";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMount } from "react-use";

type FormValues = {
  cdSkus: string;
  notes?: string;
  boxStatus: { label: string; value: BoxStatus };
};

const BOX_STATUS_DISPLAY_TEXT = {
  [BoxStatus.MISSING]: "MISSING",
  [BoxStatus.OTHER]: "OTHER",
};

export const useBoxReconciliationTool = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues: {
      cdSkus: searchParams.get("cdskus") || "",
      notes: "",
    },
  });

  const { inboundVisibilityClient } = useClientsWithAuth();

  // Initialize form with URL parameters
  useMount(() => {
    const cdskusParam = searchParams.get("cdskus");
    if (cdskusParam) {
      setValue("cdSkus", cdskusParam);
    }
  });

  const cdSkusController = useController({
    name: "cdSkus",
    control,
    rules: {
      required: "CDSKUs are required",
    },
  });

  const notesController = useController({
    name: "notes",
    control,
    rules: {
      required: "Notes are required",
    },
    defaultValue: "",
  });

  const boxStatusController = useController({
    name: "boxStatus",
    control,
    rules: {
      required: true,
    },
  });

  const statusFilterOptions = useMemo(
    () =>
      Object.entries(BOX_STATUS_DISPLAY_TEXT).map(([key, value]) => ({
        label: value,
        value: key as BoxStatus,
      })),
    []
  );

  const onSubmitBoxReconciliationsTool: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsSubmitting(true);
      const cdSkusInput = data.cdSkus;

      // Split and clean the input into an array of boxLabels
      const boxLabels = cdSkusInput
        .split(",")
        .map((sku) => sku.trim())
        .filter((sku) => sku !== "");

      if (boxLabels.length === 0) {
        toast.error("Please enter at least one valid CDSKU", { position: "top-right" });
        setIsSubmitting(false);
        return;
      }

      const notesInput = data.notes?.trim();
      const notes = notesInput ? notesInput : undefined;
      const boxStatus = data.boxStatus.value;

      // Update URL with current CDSKUs
      setSearchParams({ cdskus: cdSkusInput });

      // Prepare payload
      const payload = { boxLabels, notes, status: boxStatus };

      // Call the API
      await inboundVisibilityClient.createBoxReconciliations(payload);
      toast.success("Submission successful", { position: "top-right" });
      resetAll();
    } catch (error) {
      handleInboundVisibilityError({ fn: "useBoxReconciliationTool.onSubmitBoxReconciliationsTool" }, error as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  function resetAll() {
    reset();
    setIsSubmitting(false);
    // Clear URL parameters
    navigate(".", { replace: true });
  }

  // Update URL when cdSkus input changes
  const updateUrlWithCdSkus = (cdSkus: string) => {
    if (cdSkus) {
      setSearchParams({ cdskus: cdSkus });
    } else {
      setSearchParams({});
    }
  };

  return {
    onSubmitBoxReconciliationTool: handleSubmit(onSubmitBoxReconciliationsTool),
    cdSkusController,
    notesController,
    boxStatusController,
    resetAll,
    isSubmitting,
    statusFilterOptions,
    updateUrlWithCdSkus,
  };
};
