import { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
  ColumnDef,
} from "@tanstack/react-table";
import styled from "@emotion/styled";
import { Input, InputProps, DefaultTheme, ThemeProps } from "@deliverr/ui";

interface GenericTableProps<T> {
  data: T[];
  columns: ColumnDef<T, any>[];
  showSearch?: boolean;
  excludeFromSearch?: string[];
}

const StyledHeaderRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    cursor: pointer;
  }
`;

const StyledHeaderCell = styled.th`
  padding: 0.375rem 0.25rem;
  text-align: start;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledBodyRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

const StyledBodyCell = styled.td`
  padding: 0.375rem 0.25rem;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledInput = styled(Input)<InputProps, DefaultTheme>`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.S3};
  `}
`;

const StyledTable = styled.table<ThemeProps>`
  ${({ theme }) => `
    width: 100%;
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[500]};
  `}
`;

export function Table<T>({ data, columns, showSearch = true, excludeFromSearch = [] }: GenericTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const modifiedColumns = columns.map((column) => ({
    ...column,
    enableGlobalFilter: !excludeFromSearch.map((str) => str.toLowerCase()).includes(column.id?.toLowerCase() || ""),
  }));

  const table = useReactTable({
    data,
    columns: modifiedColumns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div>
      {showSearch && (
        <StyledInput
          value={globalFilter ?? ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search all columns..."
        />
      )}
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <StyledHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <StyledHeaderCell key={header.id} onClick={header.column.getToggleSortingHandler()}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{ asc: " 🔼", desc: " 🔽" }[header.column.getIsSorted() as string] ?? null}
                </StyledHeaderCell>
              ))}
            </StyledHeaderRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <StyledBodyRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <StyledBodyCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </StyledBodyCell>
              ))}
            </StyledBodyRow>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
}
