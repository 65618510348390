import React from "react";
import { IconV2, ThemeProps } from "@deliverr/ui";
import { SortDir } from "types/SortDir";
import styled from "@emotion/styled";

interface SortIconProps {
  sort: { fieldName: string; direction: string };
  handleSort: () => void;
  isActive: boolean;
}

interface StyledIconProps extends ThemeProps {
  isActive: boolean;
}

const StyledIcon = styled.div<StyledIconProps>`
  ${({ theme, isActive }) => `
    display: inline-block;
    margin-left: ${theme.spacing.S1};
    cursor: pointer;
    color: ${isActive ? theme.colors.NEUTRAL["500"] : theme.colors.NEUTRAL["200"]};
  `}
`;

const SortIcon: React.FC<SortIconProps> = ({ sort, handleSort, isActive }) => {
  return (
    <span onClick={handleSort} style={{ cursor: "pointer" }}>
      <StyledIcon isActive={isActive}>
        {sort.direction === SortDir.ASC ? <IconV2 type="arrow-up" /> : <IconV2 type="arrow-down" />}
      </StyledIcon>
    </span>
  );
};

export default SortIcon;
