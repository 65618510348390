import { DefaultTheme } from "@deliverr/ui";
import { useTheme } from "emotion-theming";
import { noop } from "lodash/fp";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMedia } from "react-use";
import { useNavigate, useLocation } from "react-router-dom";

export type SidebarMode = "MOBILE" | "DESKTOP";
export type SidebarState = "HIDDEN" | "PINNED_OPEN" | "HOVER_OPEN" | "MODAL_OPEN";

export interface HoverChangeEvent {
  isOpenTargetHovered: boolean;
  isMenuHovered: boolean;
}

export interface SidebarContextProps {
  mode: SidebarMode;
  state: SidebarState;
  isExpanded: boolean;
  onOpenTargetClick: () => void;
  onOpenButtonClick: () => void;
  onHideButtonClick: () => void;
  onModalOverlayClick: () => void;
  onHoverChange: (ev: HoverChangeEvent) => void;
  onItemClick: () => void;
  canGoBack: boolean;
  goBack: () => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  mode: "DESKTOP",
  state: "PINNED_OPEN",
  isExpanded: true,
  onOpenTargetClick: noop,
  onOpenButtonClick: noop,
  onHideButtonClick: noop,
  onModalOverlayClick: noop,
  onHoverChange: noop,
  onItemClick: noop,
  canGoBack: false,
  goBack: noop,
});

export const SidebarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme<DefaultTheme>();
  const isMedium = useMedia(`(min-width: ${theme.breakpoints.MD})`);
  const mode: SidebarMode = isMedium ? "DESKTOP" : "MOBILE";
  const [state, setState] = useState<SidebarState>(mode === "DESKTOP" ? "PINNED_OPEN" : "HIDDEN");
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we can go back by examining the current pathname
  const canGoBack = location.pathname !== "/";
  const goBack = () => {
    navigate(-1);
  };

  // Automatically show/hide sidebar when width changes, if appropriate.
  // For example: resizing window on desktop, rotating a tablet.
  useEffect(() => {
    switch (mode) {
      case "MOBILE":
        setState("HIDDEN");
        break;
      case "DESKTOP":
        setState("PINNED_OPEN");
        break;
    }
  }, [mode]);

  const value = useMemo(() => {
    const isExpanded = state !== "HIDDEN";

    const onOpenTargetClick = () => {
      if (mode === "MOBILE") {
        setState("MODAL_OPEN");
      }
    };

    const onOpenButtonClick = () => setState(mode === "MOBILE" ? "MODAL_OPEN" : "PINNED_OPEN");

    const onHideButtonClick = () => setState("HIDDEN");

    const onModalOverlayClick = () => setState("HIDDEN");

    const onHoverChange = ({ isOpenTargetHovered, isMenuHovered }: HoverChangeEvent) => {
      if (state === "HIDDEN" && mode !== "MOBILE" && isOpenTargetHovered) {
        setState("HOVER_OPEN");
      } else if (state === "HOVER_OPEN" && !isOpenTargetHovered && !isMenuHovered) {
        setState("HIDDEN");
      }
    };

    const onItemClick = () => {
      if (state === "MODAL_OPEN") {
        setState("HIDDEN");
      }
    };

    return {
      mode,
      state,
      isExpanded,
      onOpenTargetClick,
      onOpenButtonClick,
      onHideButtonClick,
      onModalOverlayClick,
      onHoverChange,
      onItemClick,
      canGoBack,
      goBack,
    };
  }, [mode, state, setState, canGoBack]);

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => useContext(SidebarContext);
