import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState } from "react";
import { logError } from "utils/logger";
import { UnlabeledBoxData } from "@deliverr/inbound-client";
import { useAsyncFn, useMount } from "react-use";
import { groupBy } from "lodash";
import { useController, useForm } from "react-hook-form";
import { useOnFormValueChange } from "hooks/useOnFormValueChange";
import { parseISO, isValid } from "date-fns";

type FormValues = {
  facilityId: string;
  dsku: string;
  sellerId: string;
  startDate: string;
  endDate: string;
};

export const useUnlabeledBoxTool = () => {
  const [boxesAwaitingAttribution, setBoxesAwaitingAttribution] = useState<UnlabeledBoxData[]>([]);
  const [filteredBoxes, setFilteredBoxes] = useState<UnlabeledBoxData[]>([]);
  const [selectedBox, setSelectedBox] = useState<string | null>(null);
  const { control, getValues, watch } = useForm<FormValues>({});
  const { inboundClient } = useClientsWithAuth();

  useOnFormValueChange(watch, () => {
    const { facilityId, dsku, sellerId, startDate, endDate } = getValues();

    if (!facilityId && !dsku && !sellerId && !startDate && !endDate) {
      setFilteredBoxes(boxesAwaitingAttribution);
      return;
    }

    const parsedStartDate = startDate ? parseISO(startDate) : null;
    const parsedEndDate = endDate ? parseISO(endDate) : null;
    const validParsedStartDate = parsedStartDate && isValid(parsedStartDate) ? parsedStartDate : null;
    const validParsedEndDate = parsedEndDate && isValid(parsedEndDate) ? parsedEndDate : null;

    const unlabeledBoxesByBoxLabel = groupBy(boxesAwaitingAttribution, "boxLabel");

    const filterBoxes = (boxes: UnlabeledBoxData[]) => {
      return boxes.filter((box) => {
        const boxDate = box.boxCreatedAt;
        const matchesFacilityId = !facilityId || box.facilityId.toLowerCase().includes(facilityId.toLowerCase().trim());
        const matchesDsku = !dsku || box.dsku.toLowerCase().includes(dsku.toLowerCase().trim());
        const matchesSellerId = !sellerId || box.sellerId.toLowerCase().includes(sellerId.toLowerCase().trim());
        const matchesStartDate = !validParsedStartDate || boxDate >= validParsedStartDate;
        const matchesEndDate = !validParsedEndDate || boxDate <= validParsedEndDate;
        return matchesFacilityId && matchesDsku && matchesSellerId && matchesStartDate && matchesEndDate;
      });
    };

    const filteredGroups: Record<string, UnlabeledBoxData[]> = {};
    for (const [label, boxes] of Object.entries(unlabeledBoxesByBoxLabel)) {
      const filteredBoxes = filterBoxes(boxes);
      if (filteredBoxes.length > 0) {
        filteredGroups[label] = boxes;
      }
    }

    setFilteredBoxes(Object.values(filteredGroups).flat());
  });

  const facilityIdController = useController({
    name: "facilityId",
    control,
    defaultValue: "",
  });

  const dskuController = useController({
    name: "dsku",
    control,
    defaultValue: "",
  });

  const sellerIdController = useController({
    name: "sellerId",
    control,
    defaultValue: "",
  });

  const startDateController = useController({
    name: "startDate",
    control,
    defaultValue: "",
  });

  const endDateController = useController({
    name: "endDate",
    control,
    defaultValue: "",
  });

  useMount(() => {
    loadBoxesAwaitingAttribution();
  });

  const [{ loading: loadingBoxes }, loadBoxesAwaitingAttribution] = useAsyncFn(async () => {
    try {
      const boxes = (await inboundClient.getUnlabeledContainersAwaitingAttribution())?.data?.map((box) => ({
        ...box,
        boxCreatedAt: new Date(box.boxCreatedAt),
      }));
      const sortedBoxes = boxes?.sort((a, b) => b.boxCreatedAt.getTime() - a.boxCreatedAt.getTime()) || [];
      setBoxesAwaitingAttribution(sortedBoxes);
      setFilteredBoxes(sortedBoxes);
    } catch (error) {
      logError({ fn: "useUnlabeledBoxTool.loadBoxesAwaitingAttribution" }, error as Error);
      toast.error("Failed to load boxes awaiting attribution");
    }
  }, [inboundClient, boxesAwaitingAttribution]);

  const unlabeledBoxesByBoxLabel = groupBy(filteredBoxes, "boxLabel");

  const onAssignedToShipment = (boxLabel: string) => {
    setSelectedBox(null);
    setBoxesAwaitingAttribution((prev) => prev.filter((box) => box.boxLabel !== boxLabel));
    setFilteredBoxes((prev) => prev.filter((box) => box.boxLabel !== boxLabel));
  };

  return {
    boxesAwaitingAttribution,
    loadingBoxes,
    setSelectedBox,
    selectedBox,
    unlabeledBoxesByBoxLabel,
    onAssignedToShipment,
    facilityIdController,
    dskuController,
    sellerIdController,
    startDateController,
    endDateController,
    filteredBoxes,
  };
};
