export enum MismatchedDimensionsCancellationReason {
  BAD_GREEN_BOX = "BAD_GREEN_BOX",
  BAD_PHOTOS = "BAD_PHOTOS",
  INACCURATE_DIMS_CAPTURE = "INACCURATE_DIMS_CAPTURE",
  ITEM_PLACEMENT_ISSUE = "ITEM_PLACEMENT_ISSUE",
  OTHER = "OTHER",
}

export const MISMATCHED_DIMENSIONS_CANCELLATION_REASON_DISPLAY_TEXT = {
  [MismatchedDimensionsCancellationReason.BAD_GREEN_BOX]: "Bad Green Box",
  [MismatchedDimensionsCancellationReason.BAD_PHOTOS]: "Bad Photos",
  [MismatchedDimensionsCancellationReason.INACCURATE_DIMS_CAPTURE]: "Inaccurate Dims Capture",
  [MismatchedDimensionsCancellationReason.ITEM_PLACEMENT_ISSUE]: "Item Placement Issue",
  [MismatchedDimensionsCancellationReason.OTHER]: "Other",
};
