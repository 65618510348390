import { SelectOptionType, toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { OrganizationStatus } from "@deliverr/business-types";
import { SellerDueToleranceValuePast30DaysType } from "@deliverr/billing-types";
import { DEFAULT_TOLERANCE_VALUE } from "../constants";
import { OrganizationStatusReasonMap } from "./StatusWithStatusReasonMap";
import { useIsCreditRiskAdmin } from "./useIsCreditRiskAdmin";

interface UseSellerDetailsProps {
  setToleranceAmount: (amount: number) => void;
  setStatus: (status: OrganizationStatus) => void;
  status: OrganizationStatus;
}

export const useSellerDetails = ({ setToleranceAmount, setStatus, status }: UseSellerDetailsProps) => {
  const { sellerId } = useParams() as {
    sellerId: string;
  };
  const { billingClient, organizationClient } = useClientsWithAuth();
  const navigate = useNavigate();
  const isCreditRiskAdmin = useIsCreditRiskAdmin();
  const [statusReason, setStatusReason] = useState<string>("");
  const [inputStatus, setInputStatus] = useState<string>("");
  const [inputStatusReason, setInputStatusReason] = useState<string>("");
  const [isStatusModalOpen, setStatusModal] = useState(false);
  const [inputToleranceAmount, setInputToleranceAmount] = useState<number>(DEFAULT_TOLERANCE_VALUE);
  const [isEditAmountModalOpen, setEditAmountModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const openStatusModal = () => {
    setStatusModal(true);
  };

  const closeStatusModal = () => {
    setStatusModal(false);
  };

  const openEditAmountModal = () => {
    setEditAmountModal(true);
  };

  const closeEditAmountModal = () => {
    setEditAmountModal(false);
  };

  useEffect(() => {
    setInputStatusReason("");
  }, [inputStatus]);

  const statusOptions: SelectOptionType[] = useMemo(() => {
    return Object.values(OrganizationStatus)
      .filter((organizationStatus) => organizationStatus !== status)
      .map((status) => ({
        label: status,
        value: status,
      }));
  }, [status]);

  const statusReasonOptions: SelectOptionType[] = useMemo(() => {
    if (!inputStatus) return [];
    return OrganizationStatusReasonMap[inputStatus].map((reason) => ({
      label: reason,
      value: reason,
    }));
  }, [inputStatus]);

  const fetchOrganizationDetails = async () => {
    const organizationDetails = await organizationClient.getSeller(sellerId, false);
    setStatus(organizationDetails?.data?.status);
    setStatusReason(organizationDetails?.data?.statusReason);
  };

  const updateSellerStatus = async () => {
    setLoading(true);
    try {
      await organizationClient.updateOrganizationActiveStatus(sellerId, inputStatus, inputStatusReason);
      setStatus(inputStatus as OrganizationStatus);
      setStatusReason(inputStatusReason);
      toast.success("Seller status updated successfully", { position: "top-right" });
    } catch (err) {
      toast.error("Unable to update seller status", { position: "top-right" });
    } finally {
      setLoading(false);
      closeStatusModal();
    }
  };

  const updateToleranceAmount = async () => {
    setLoading(true);
    try {
      await billingClient.updateSellerConfig({
        sellerId,
        dueToleranceValuePast30Days: inputToleranceAmount,
        dueToleranceValuePast30DaysType: SellerDueToleranceValuePast30DaysType.ABSOLUTE,
      });
      toast.success("Tolerance amount updated successfully", { position: "top-right" });
      setToleranceAmount(inputToleranceAmount);
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        try {
          await billingClient.createSellerConfig({
            sellerId,
            dueToleranceValuePast30Days: inputToleranceAmount,
            dueToleranceValuePast30DaysType: SellerDueToleranceValuePast30DaysType.ABSOLUTE,
          });
          toast.success("Tolerance amount added successfully", { position: "top-right" });
          setToleranceAmount(inputToleranceAmount);
        } catch (addErr) {
          toast.error("Unable to add tolerance amount", { position: "top-right" });
        }
      } else {
        toast.error("Unable to update tolerance amount", { position: "top-right" });
      }
    } finally {
      setLoading(false);
      closeEditAmountModal();
    }
  };

  const { value } = useAsync(async () => {
    setLoading(true);
    try {
      const sellerDueResponse = await billingClient.getSellerDues(sellerId);
      return sellerDueResponse?.value;
    } finally {
      setLoading(false);
    }
  }, [sellerId, billingClient]);

  useAsync(async () => {
    setLoading(true);
    try {
      await fetchOrganizationDetails();
    } catch (error) {
      navigate(-1);
      toast.error("Unable to fetch seller details", { position: "top-right", autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  }, [sellerId, organizationClient]);

  useAsync(async () => {
    setLoading(true);
    try {
      const sellerConfig = await billingClient.getSellerConfig(sellerId);
      const PastDue30DaysToleranceAmount = sellerConfig?.value?.dueToleranceValuePast30Days ?? DEFAULT_TOLERANCE_VALUE;
      setToleranceAmount(PastDue30DaysToleranceAmount);
      setInputToleranceAmount(PastDue30DaysToleranceAmount);
    } finally {
      setLoading(false);
    }
  }, [sellerId, billingClient]);

  return {
    sellerId,
    inputToleranceAmount,
    setInputToleranceAmount,
    sellerDue: value,
    statusReason,
    inputStatus,
    inputStatusReason,
    setInputStatus,
    setInputStatusReason,
    statusOptions,
    statusReasonOptions,
    updateSellerStatus,
    isStatusModalOpen,
    openStatusModal,
    closeStatusModal,
    isEditAmountModalOpen,
    openEditAmountModal,
    closeEditAmountModal,
    loading,
    updateToleranceAmount,
    isCreditRiskAdmin,
  };
};
