import { createColumnHelper } from "@tanstack/react-table";
import { Table } from "components/Table/Table";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";
import { InboundDetailViewEntry } from "./useInboundDetailView";
import { Path } from "paths";

const StyledLink = styled(Link)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.BLUE[300]};
    &:hover {
      color: ${theme.colors.BLUE[500]};
      text-decoration: underline;
    }
  `
);

const columnHelper = createColumnHelper<InboundDetailViewEntry>();

const columns = [
  columnHelper.accessor("boxLabel", {
    id: "boxLabel",
    header: "Box Label",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("lastStatusUpdateAt", {
    id: "lastStatusUpdateAt",
    header: "Last Status Update",
    cell: (info) => new Date(info.getValue()).toLocaleString(),
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: "Status",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("owner", {
    id: "owner",
    header: "Owner",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("violatedAt", {
    id: "violatedAt",
    header: "SLA Violated At",
    cell: (info) => (info.getValue() ? new Date(info.getValue()!).toLocaleString() : ""),
  }),
  columnHelper.accessor("daysPastSla", {
    id: "daysPastSla",
    header: "Days Past SLA",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("boxLabel", {
    id: "reconciliation",
    header: "Reconciliation",
    cell: (info) => {
      const boxLabel = info.getValue();
      const encodedBoxLabel = encodeURIComponent(boxLabel);
      return (
        <StyledLink
          to={{
            pathname: `/${Path.boxReconciliationTool}`,
            search: `?cdskus=${encodedBoxLabel}`,
          }}
        >
          Reconcile
        </StyledLink>
      );
    },
  }),
];

export function InboundDetailTable({ data }: { data: InboundDetailViewEntry[] }) {
  return (
    <div>
      <Table data={data} columns={columns} showSearch={true} excludeFromSearch={["daysPastSla"]} />
    </div>
  );
}
