import { FC } from "react";
import { Card, ThemeProps, Text, Button, Stack, Pill, defaultTheme, PillColors } from "@deliverr/ui";
import { Container } from "components/Container";
import styled from "@emotion/styled";
import { LoadingSpinner } from "components/LoadingSpinner";
import { CreditBlockListStatus } from "../CreditBlockList/CreditBlockTable/CreditBlockStatus/CreditBlockListStatus";
import { ChangeStatusModal } from "./ChangeStatusModal";
import { EditAmountModal } from "./EditAmountModal";
import { useSellerDetails } from "./useSellerDetails";
import { OrganizationStatus } from "@deliverr/business-types";

export const CreditBlockCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const CardTitle = styled(Text)<ThemeProps>`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
    font-weight: ${theme.font.weight.BOLD};
  `}
`;

export const StyledButton = styled(Button)`
  width: fit-content;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Divider = styled.hr<ThemeProps>`
  ${({ theme }) => `
    border-top: ${theme.border.type} ${theme.border.width.B1} ${defaultTheme.colors.NEUTRAL[80]};
    margin-top: ${theme.spacing.S6};
  `}
`;

const BoxContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Box = styled.div<ThemeProps>`
  ${({ theme }) => `
  flex: 1;
  height: 150px;
  padding: ${theme.spacing.S6} 0 0 ${theme.spacing.S6};
    border-right: ${theme.border.type} ${theme.border.width.B1} ${defaultTheme.colors.NEUTRAL[80]};
  &:last-child {
    border-right: none;
  }
  `}
`;

const BoxText = styled(Text)`
  margin: 0;
`;

const TopContainer = styled.div<ThemeProps>`
  ${({ theme }) => `
     margin: ${theme.spacing.S6} ${theme.spacing.S6} 0rem ${theme.spacing.S6};
  `}
`;

const EditAmountText = styled(Text)<ThemeProps>`
  ${({ theme }) => `
color: ${theme.colors.BLUE[300]};
cursor: pointer;
margin-top: ${theme.spacing.S2};
 &:hover {
    color: ${theme.colors.BLUE[400]};
  }
`}
`;

export interface SellerDetailsProps {
  toleranceAmount: number;
  setToleranceAmount: (amount: number) => void;
  status: OrganizationStatus;
  setStatus: (status: OrganizationStatus) => void;
}

export const SellerDetails: FC<SellerDetailsProps> = ({ toleranceAmount, setToleranceAmount, status, setStatus }) => {
  const {
    sellerId,
    sellerDue,
    statusOptions,
    statusReasonOptions,
    isStatusModalOpen,
    openStatusModal,
    closeStatusModal,
    updateSellerStatus,
    isEditAmountModalOpen,
    openEditAmountModal,
    closeEditAmountModal,
    loading,
    updateToleranceAmount,
    statusReason,
    inputStatus,
    inputStatusReason,
    setInputStatus,
    setInputStatusReason,
    inputToleranceAmount,
    setInputToleranceAmount,
    isCreditRiskAdmin,
  } = useSellerDetails({ setToleranceAmount, setStatus, status });

  return (
    <Container fullWidth={true}>
      <CreditBlockCard>
        <LoadingSpinner loading={loading} text="Loading Credit Block Details">
          <TopContainer>
            <HeaderContainer>
              <CardTitle size="h2">Seller ID {sellerId}</CardTitle>
              {isCreditRiskAdmin && <StyledButton onClick={openStatusModal}>Change Status</StyledButton>}
            </HeaderContainer>
            <Stack direction="HORIZONTAL">
              {status && <CreditBlockListStatus status={status} />}
              {statusReason && <Pill color={PillColors.GRAY}>{statusReason}</Pill>}
            </Stack>
          </TopContainer>
          <Divider />
          <BoxContainer>
            <Box>
              <BoxText>Past due tolerance amount</BoxText>
              <CardTitle size="h2">${toleranceAmount}</CardTitle>
              {isCreditRiskAdmin && <EditAmountText onClick={openEditAmountModal}>Edit Amount</EditAmountText>}
            </Box>
            <Box>
              <BoxText>Total balance</BoxText>
              <CardTitle size="h2">${sellerDue?.totalBalance ?? 0}</CardTitle>
            </Box>
            <Box>
              <BoxText>Total balance past due</BoxText>
              <CardTitle size="h2">${sellerDue?.balanceDue ?? 0}</CardTitle>
            </Box>
            <Box>
              <BoxText>30+ days past due</BoxText>
              <CardTitle size="h2">${sellerDue?.balanceDuePast30Days ?? 0}</CardTitle>
            </Box>
          </BoxContainer>
          <ChangeStatusModal
            updateSellerStatus={updateSellerStatus}
            statusOptions={statusOptions}
            statusReasonOptions={statusReasonOptions}
            setStatus={setInputStatus}
            setStatusReason={setInputStatusReason}
            isStatusModalOpen={isStatusModalOpen}
            closeStatusModal={closeStatusModal}
            isUpdateStatusDisabled={!inputStatus || !inputStatusReason}
            status={inputStatus}
            statusReason={inputStatusReason}
          />
          <EditAmountModal
            setToleranceAmount={setInputToleranceAmount}
            toleranceAmount={inputToleranceAmount}
            isEditAmountModalOpen={isEditAmountModalOpen}
            closeEditAmountModal={closeEditAmountModal}
            updateToleranceAmount={updateToleranceAmount}
            isUpdateAmountDisabled={!inputToleranceAmount}
          />
        </LoadingSpinner>
      </CreditBlockCard>
    </Container>
  );
};
