import { useSellerConfigTable } from "./useSellerConfigTable";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TableHeader } from "./Table/TableHeader";
import { TableBody } from "./Table/TableBody";
import { isEmpty } from "lodash";
import { FC } from "react";
import { ItalicText, StyledCard, StyledText, ScrollableContainer, StyledTable } from "./HistoryTable";

interface SellerConfigTableProps {
  toleranceAmount: number;
}

export const SellerConfigTable: FC<SellerConfigTableProps> = ({ toleranceAmount }) => {
  const { table, loading, currentPageItems } = useSellerConfigTable({ toleranceAmount });
  return (
    <StyledCard>
      <LoadingSpinner loading={loading} text="Loading Seller Config History">
        {!isEmpty(currentPageItems) && (
          <>
            <StyledText size="bodyLarge">
              Seller Config History (<ItalicText>Top 100 Config</ItalicText>)
            </StyledText>
            <ScrollableContainer>
              <StyledTable>
                <TableHeader headerGroups={table.getHeaderGroups()} />
                <TableBody rowModel={table.getRowModel()} />
              </StyledTable>
            </ScrollableContainer>
          </>
        )}
      </LoadingSpinner>
    </StyledCard>
  );
};
