import { BoxSlaOwner, BoxStatus } from "@deliverr/inbound-visibility-client";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState } from "react";
import { useAsyncFn, useMount } from "react-use";
import { handleInboundVisibilityError } from "utils/handleInboundVisibilityError";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export interface InboundDetailViewEntry {
  boxLabel: string;
  violatedAt: Date | null;
  lastStatusUpdateAt: Date;
  daysPastSla: number;
  owner: BoxSlaOwner | null;
  status: BoxStatus;
}

interface InboundFormData {
  shipmentId: string;
}

export const useInboundDetailView = () => {
  const { shipmentId: routeShipmentId } = useParams<{ shipmentId: string }>();
  const navigate = useNavigate();
  const { inboundVisibilityClient } = useClientsWithAuth();
  const [inboundDetailEntries, setInboundDetailEntries] = useState<InboundDetailViewEntry[]>([]);

  const { control, handleSubmit } = useForm<InboundFormData>({
    defaultValues: {
      shipmentId: routeShipmentId || "",
    },
  });

  const [{ loading: loadingEntries }, loadInboundsList] = useAsyncFn(
    async (shipmentId: number) => {
      try {
        const entries = await inboundVisibilityClient.getBoxDetailsByShipmentId({ shipmentId });
        setInboundDetailEntries(entries);
      } catch (error) {
        handleInboundVisibilityError({ fn: "useInboundDetailView" }, error);
      }
    },
    [inboundVisibilityClient, setInboundDetailEntries]
  );

  useMount(() => {
    if (routeShipmentId && routeShipmentId !== ":shipmentId") {
      loadInboundsList(Number(routeShipmentId));
    }
  });

  const onSubmit = handleSubmit((data) => {
    const newShipmentId = Number(data.shipmentId);
    navigate(`/inbound-visibility/forwarding-inbound/${newShipmentId}`);
    loadInboundsList(newShipmentId);
  });

  return {
    loadingEntries,
    inboundDetailEntries,
    control,
    onSubmit,
  };
};
