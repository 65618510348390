import { useState, useMemo } from "react";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { handleNonComplianceError } from "utils/handleNonComplianceError";
import {
  MismatchedDimensionsCancellationReason,
  MISMATCHED_DIMENSIONS_CANCELLATION_REASON_DISPLAY_TEXT,
} from "./MismatchedDimensionsCancellationReason";

type FormValues = {
  reason: { label: string; value: MismatchedDimensionsCancellationReason };
  reasonDesc?: string;
};

export const useMismatchedDimensionsIssueCancellationAction = (
  cancelIssue: (reason: string, reasonDesc?: string | null) => Promise<void>
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm<FormValues>({});

  const reasonController = useController({
    name: "reason",
    defaultValue: {
      label:
        MISMATCHED_DIMENSIONS_CANCELLATION_REASON_DISPLAY_TEXT[MismatchedDimensionsCancellationReason.BAD_GREEN_BOX],
      value: MismatchedDimensionsCancellationReason.BAD_GREEN_BOX,
    },
    control,
    rules: {
      required: true,
    },
  });

  const reasonDescriptionController = useController({
    name: "reasonDesc",
    control,
    defaultValue: "",
    rules: {
      maxLength: 50,
    },
  });

  const reasonValue = watch("reason");

  const cancellationReasonOptions = useMemo(
    () =>
      Object.entries(MISMATCHED_DIMENSIONS_CANCELLATION_REASON_DISPLAY_TEXT).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [MISMATCHED_DIMENSIONS_CANCELLATION_REASON_DISPLAY_TEXT]
  );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      await cancelIssue(data.reason.value, data.reasonDesc);
      setIsSubmitting(false);
      reset();
      return;
    } catch (error) {
      setIsSubmitting(false);
      handleNonComplianceError({ fn: "useIssueCancellationTool.onSubmit" }, error);
    }
  };

  return {
    reasonController,
    reasonDescriptionController,
    reasonValue,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
    cancellationReasonOptions,
  };
};
