import { ButtonIcon, DefaultTheme, isMobile } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useSidebarContext } from "components/Sidebar";
import React, { FC } from "react";

const Outer = styled.div<{}, DefaultTheme>`
  margin: 0px 15px;
  margin-bottom: 0;
  position: relative;
  ${({ theme }) =>
    isMobile(theme) &&
    `
    margin: 30px ${theme.spacing.S2};
  `}
`;

const Inner = styled.div<Pick<ContainerProps, "fullWidth">, DefaultTheme>`
  margin: 0 auto;
  padding: 0 15px;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "1028px")};
  ${({ theme }) =>
    isMobile(theme) &&
    `
    padding: 0;
  `}
`;

export interface ContainerProps {
  minHeight?: string;
  className?: string;
  fullWidth?: boolean;
  children: React.ReactNode;
}

/**
 * Horizontally centered container with standard padding and maximum width.
 */
export const Container: FC<ContainerProps> = ({ minHeight, fullWidth, children, className }) => {
  const { goBack, canGoBack } = useSidebarContext();
  return (
    <>
      {canGoBack && (
        <ButtonIcon
          style={{ margin: 15 }}
          size="LG"
          iconType="arrow-left"
          transparent
          onClick={goBack}
          aria-label="Go back"
        />
      )}
      <Outer style={{ minHeight }} id="outerContainer" className={className}>
        <Inner fullWidth={fullWidth}>{children}</Inner>
      </Outer>
    </>
  );
};
