import { SortDir } from "types/SortDir";
import { OrganizationStatus } from "@deliverr/business-types";

export enum SellerStatusFilter {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export interface SortFields {
  fieldName: string;
  direction: SortDir;
}

export interface CreditBlockItem {
  readonly sellerId: string;
  readonly dueToleranceValuePast30Days: number;
  readonly totalBalance: number;
  readonly balanceDue: number;
  readonly balanceDuePast30Days: number;
  readonly status: OrganizationStatus;
  readonly statusReason: string;
}
