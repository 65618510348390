import { Button, Card, ThemeProps, Input, Text, Grid } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useIssueActionTool } from "./useIssueActionTool";
import { CardHeading } from "../components/CardHeading";
import { IssueSpecificDetails } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Actions } from "./components/Actions/Actions";
import { ReimbursementForm } from "./components/ReimbursementForm/ReimbursementForm";
import { IssueType } from "@deliverr/non-compliance-client";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const IssueActionTool = () => {
  const { issueId, isLoading, searchForIssue, issueDetails, actionProps } = useIssueActionTool();
  return (
    <Container>
      <Card>
        <CardHeading>Issue Tool</CardHeading>
        <Text size="body">Enter an Issue Id to see details and available actions.</Text>
        <StyledForm>
          <Input
            {...issueId.field}
            helpText="The id of the Issue"
            placeholder="NC-"
            label="Issue Id"
            required
            type="string"
            hasError={!!issueId.fieldState.error}
            errorMessage={issueId.fieldState.error?.message}
          />
          <Button type="submit" onClick={searchForIssue} loading={isLoading}>
            Find Issue
          </Button>
        </StyledForm>
      </Card>
      <br />
      {isLoading && <LoadingSpinner loading />}
      {issueDetails && <IssueSpecificDetails {...issueDetails} />}
      <br />
      {issueDetails && actionProps && (
        <Grid columns="1fr 1fr">
          <Actions isLoading={isLoading} issue={issueDetails.issue} {...actionProps} />
          {issueDetails.issue.issueType === IssueType.DAMAGED_PRODUCT && (
            <ReimbursementForm
              issueId={issueDetails.issue.id}
              issueType={issueDetails.issue.issueType}
              issueEntities={issueDetails.issueEntities}
            />
          )}
        </Grid>
      )}
      <br />
    </Container>
  );
};
