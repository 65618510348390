import { Button, Input, Select, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useMismatchedDimensionsIssueCancellationAction } from "./useMismatchedDimensionsIssueCancellationAction";
import { MismatchedDimensionsCancellationReason } from "./MismatchedDimensionsCancellationReason";
import { IssueCancellationHeader } from "./IssueCancellationHeader";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const MismatchedDimensionsIssueCancellationAction: React.FC<{
  cancelIssue: (reason: string, reasonDesc?: string | null) => Promise<void>;
}> = ({ cancelIssue }) => {
  const {
    reasonController,
    reasonDescriptionController,
    reasonValue,
    onSubmit,
    isSubmitting,
    cancellationReasonOptions,
  } = useMismatchedDimensionsIssueCancellationAction(cancelIssue);

  return (
    <StyledForm onSubmit={onSubmit}>
      <br />
      <hr />
      <br />
      <IssueCancellationHeader />

      <Select {...reasonController.field} label="Reason" required options={cancellationReasonOptions} />
      {reasonValue?.value === MismatchedDimensionsCancellationReason.OTHER && (
        <Input
          {...reasonDescriptionController.field}
          required
          maxLength={50}
          helpText="Short description for the reason of cancellation"
          type="string"
          label="Short Description"
          hasError={!!reasonDescriptionController.fieldState.error}
          errorMessage={reasonDescriptionController.fieldState.error?.message}
        />
      )}
      <Button type="submit" loading={isSubmitting} appearance={"DANGER"}>
        Cancel Issue
      </Button>
    </StyledForm>
  );
};
