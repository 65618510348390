import { Accordion, Anchor, Box, Grid, Text } from "@deliverr/ui";
import {
  Domain,
  EntityType,
  IssueEntityGetResponse,
  IssueGetResponse,
  IssueImageGetResponse,
  IssueStatus,
  IssueZendeskTicketGetResponse,
} from "@deliverr/non-compliance-client";
import { Field } from "./Field";
import { Thumbnails } from "./Thumbnails";
import { format } from "date-fns";
import { useState } from "react";

interface CommonIssueDetailsProps {
  issue: IssueGetResponse;
  issueEntities: IssueEntityGetResponse[];
  images: IssueImageGetResponse[];
  zendeskTickets: IssueZendeskTicketGetResponse[];
}

const statusTitles = {
  [IssueStatus.AWAITING_DISPOSAL]: "Awaiting Disposal",
  [IssueStatus.AWAITING_DISPOSITION]: "Awaiting Disposition",
  [IssueStatus.AWAITING_SUPPORT]: "Awaiting Support",
  [IssueStatus.AWAITING_FACILITY]: "Awaiting Facility",
  [IssueStatus.AWAITING_FACILITY_OR_SUPPORT]: "Awaiting Facility or Support",
  [IssueStatus.AWAITING_SUPPORT_IDENTIFICATION]: "Awaiting Support Identification",
  [IssueStatus.AWAITING_LABELING]: "Awaiting Labeling",
  [IssueStatus.RESOLVED]: "Resolved",
  [IssueStatus.CANCELLED]: "Cancelled",
  [IssueStatus.AWAITING_MERCHANT_FEEDBACK]: "Awaiting Merchant Feedback",
};

const domainTitles: Record<Domain, string> = {
  [Domain.B2B_OUTBOUNDS]: "B2B Outbounds",
  [Domain.CROSSDOCK]: "Crossdock",
  [Domain.D2C_OUTBOUNDS]: "D2C Outbounds",
  [Domain.INVENTORY]: "Inventory",
  [Domain.PREP]: "Prep",
  [Domain.RECEIVING]: "Receiving",
  [Domain.RETURNS]: "Returns",
};

const entityTypeMap: Record<EntityType, string> = {
  [EntityType.BOX]: "Cdsku",
  [EntityType.SHIPMENT]: "Replenishment Shipment",
  [EntityType.LOCATION]: "Location",
  [EntityType.JOB]: "Prep Job",
  [EntityType.PRODUCT]: "Dsku",
};

export const CommonIssueDetails = ({ issue, issueEntities, images, zendeskTickets }: CommonIssueDetailsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleOpen = () => setIsOpen((state) => !state);

  return (
    <div>
      <Accordion indicator divider isOpen={isOpen} toggleOpen={toggleOpen} headerContent={<h2>Issue Details</h2>}>
        <Grid columns="1fr 1fr" gap="S2">
          <Field label="Issue Id" value={issue.id} />
          <Field label="Issue Type" value={issue.issueType} />
          <Field label="Domain" value={domainTitles[issue.domain]} />
          <Field label="Submission Date" value={format(new Date(issue.createdAt), "MMMM dd, yyyy")} />
          <Field label="Status" value={statusTitles[issue.status]} />
          <Field label="Facility" value={issue.facilityId} />
          <Field
            label="Total # of impacted units"
            value={issueEntities.reduce((acc, ie) => acc + (ie.numberOfUnits || 0), 0)}
          />
          <Field label="Seller Id" value={issue.sellerId} />
          <Field label="Cancellation Reason" value={issue.cancellationReason} />
          <Field label="Cancellation Reason Description" value={issue.cancellationReasonDesc} />
        </Grid>
        <ZendeskTickets tickets={zendeskTickets} />
        <Thumbnails images={images} />
      </Accordion>
      <br />
      <IssueEntityCard issueEntities={issueEntities} />
    </div>
  );
};

const IssueEntityCard = ({ issueEntities }: { issueEntities: IssueEntityGetResponse[] }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleOpen = () => setIsOpen((state) => !state);

  return (
    <Accordion indicator divider isOpen={isOpen} toggleOpen={toggleOpen} headerContent={<h2>Entity Details</h2>}>
      <EntityDetails issueEntities={issueEntities} />
    </Accordion>
  );
};

const EntityDetails = ({ issueEntities }: { issueEntities: IssueEntityGetResponse[] }) => {
  return (
    <Box marginTop="S1">
      <Grid columns="1fr 1fr" gap="S2">
        {Object.values(
          // We don't need to display duplicate entities
          issueEntities.reduce<{ [key: string]: IssueEntityGetResponse }>((acc, ie) => {
            if (ie.entity && ie.entity?.entityId && !acc[ie.entity?.entityId]) {
              acc[ie.entity?.entityId] = ie;
            }

            return acc;
          }, {})
        ).map((ie) => {
          const entity = ie?.entity;
          if (!entity) return null;

          const entityLabel = entityTypeMap[entity.entityType];
          return (
            <>
              {entityLabel && <Field label="Entity Type" value={entityLabel} />}
              {entity.entityId && <Field label="Entity Id" value={entity.entityId} />}
              <Field label="Location Name" value={entity.locationName} />
              <Field label="Status" value={statusTitles[ie.status]} />
            </>
          );
        })}
      </Grid>
    </Box>
  );
};

const ZendeskTickets = ({ tickets }: { tickets: IssueZendeskTicketGetResponse[] }) => {
  return (
    <Box marginTop="S1">
      <Text bold size="label">
        Zendesk Ticket(s):
      </Text>
      <Grid columns="1fr 1fr" gap="S2">
        {tickets.map((ticket) => {
          return (
            <Field
              label={ticket.ticketType as string}
              value={
                <Anchor href={ticket.zendeskTicketUrl} target="_blank">
                  <Text size="caption">{ticket.zendeskTicketUrl}</Text>
                </Anchor>
              }
            />
          );
        })}
      </Grid>
    </Box>
  );
};
