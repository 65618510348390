import { Text } from "@deliverr/ui";

export const IssueCancellationHeader = () => {
  return (
    <>
      <Text bold>Issue Cancellation</Text>
      <Text size="body">
        This tool is used to mark a non compliance issue as cancelled. Only do this if you are confident that no further
        action is required on this issue
      </Text>
    </>
  );
};
