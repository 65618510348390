import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { SellerConfigHistoryData } from "@deliverr/billing-types";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { SELLER_CONFIG_ITEMS_MAX_LIMIT } from "../constants";
import { format } from "date-fns";
import { toast } from "@deliverr/ui";

interface UseSellerConfigTableProps {
  toleranceAmount: number;
}

export const useSellerConfigTable = ({ toleranceAmount }: UseSellerConfigTableProps) => {
  const { billingClient } = useClientsWithAuth();
  const { sellerId } = useParams() as {
    sellerId: string;
  };

  const {
    loading,
    value: currentPageItems,
    error,
  } = useAsync(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const sellerConfigHistoryResponse = await billingClient.getSellerConfigHistory(
      sellerId,
      SELLER_CONFIG_ITEMS_MAX_LIMIT
    );
    return sellerConfigHistoryResponse.value.history;
  }, [billingClient, sellerId, toleranceAmount]);

  if (error) {
    toast.error("Failed to fetch seller config history");
  }

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SellerConfigHistoryData>();
    return [
      columnHelper.accessor("entityUpdatedAt", {
        header: "Date",
        cell: (info) => format(new Date(info.getValue()), "MMM d, yyyy, h:mm:ss a"),
      }),
      columnHelper.accessor("dueToleranceValuePast30Days", {
        header: "Past Due Tolerance Amount",
      }),
      columnHelper.accessor("entityUpdatedBy.email", {
        header: "Updated By",
      }),
    ];
  }, []);

  const table = useReactTable({
    data: currentPageItems ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
    loading,
    currentPageItems,
  };
};
