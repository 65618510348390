import styled from "@emotion/styled";
import { Card, Text, ThemeProps } from "@deliverr/ui";

export const StyledTable = styled.table<ThemeProps>`
  ${({ theme }) => `
    width: 100%;
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[500]};
  `}
`;

export const StyledText = styled(Text)<ThemeProps>`
  ${({ theme }) => `
    padding: ${theme.spacing.S6} ${theme.spacing.S5};
    font-size: ${theme.font.size.F5};
    font-weight: ${theme.font.weight.BOLD};
    border-bottom: ${theme.border.type} ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]};
  `}
`;

export const StyledCard = styled(Card)<ThemeProps>`
  ${({ theme }) => `
    padding: 0;
    margin-bottom: ${theme.spacing.S6};
  `}
`;

export const ScrollableContainer = styled.div<ThemeProps>`
  max-height: 750px;
  overflow-y: auto;
`;

export const ItalicText = styled.span`
  font-style: italic;
  font-size: smaller;
`;
