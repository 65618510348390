import { PillColors } from "@deliverr/ui";
import { OrganizationStatus } from "@deliverr/business-types";

export const useCreditBlockListStatus = (status: OrganizationStatus) => {
  switch (status) {
    case OrganizationStatus.ACTIVE:
      return {
        status: "Active",
        color: PillColors.GREEN,
      };
    case OrganizationStatus.INACTIVE:
      return {
        status: "Inactive",
        color: PillColors.GRAY,
      };
    case OrganizationStatus.SUSPENDED:
      return {
        status: "Suspended",
        color: PillColors.RED,
      };
    default:
      return {
        status: undefined,
        color: undefined,
      };
  }
};
