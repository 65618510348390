import { useState } from "react";
import { DEFAULT_TOLERANCE_VALUE } from "../constants";
import { OrganizationStatus } from "@deliverr/business-types";

export const useCreditBlockDetail = () => {
  const [toleranceAmount, setToleranceAmount] = useState<number>(DEFAULT_TOLERANCE_VALUE);
  const [status, setStatus] = useState<OrganizationStatus>(OrganizationStatus.ACTIVE);

  return {
    toleranceAmount,
    setToleranceAmount,
    status,
    setStatus,
  };
};
