import { Button, Card, ThemeProps, Input, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useShipmentCompletionTool } from "./useShipmentCompletionTool";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const ShipmentCompletionTool = () => {
  const { shipmentId, completionReason, onSubmit, isSubmitting, passCode } = useShipmentCompletionTool();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmit}>
          <Text size="bodyLarge" bold>
            Shipment Completion Tool
          </Text>
          <Text size="body">This tool is used to mark a shipment complete.</Text>

          <Input
            {...shipmentId.field}
            helpText="The id of the Shipment you'd like to mark complete"
            placeholder="7878767"
            label="Shipment Id"
            required
            type="number"
            hasError={!!shipmentId.fieldState.error}
            errorMessage={shipmentId.fieldState.error?.message}
          />
          <Input
            {...completionReason.field}
            helpText="Optional reasoning for marking this shipment complete"
            placeholder="Shipment marked completed because..."
            label="Completion Reason (optional)"
            hasError={!!completionReason.fieldState.error}
            errorMessage={completionReason.fieldState.error?.message}
          />
          <Input
            {...passCode.field}
            helpText="Passcode to confirm completion for cases where we haven't received/attributed enough units"
            label="Passcode (optional)"
            hasError={!!passCode.fieldState.error}
            errorMessage={passCode.fieldState.error?.message}
          />
          <Button type="submit" loading={isSubmitting}>
            Mark Shipment complete
          </Button>
        </StyledForm>
      </Card>
    </Container>
  );
};
