import { LoadingSpinner } from "components/LoadingSpinner";
import { TableHeader } from "./Table/TableHeader";
import { TableBody } from "./Table/TableBody";
import { isEmpty } from "lodash";
import { FC } from "react";
import { useSellerHistoryTable } from "./useStatusHistoryTable";
import { OrganizationStatus } from "@deliverr/business-types";
import { ItalicText, StyledCard, StyledText, ScrollableContainer, StyledTable } from "./HistoryTable";

interface StatusHistoryTableProps {
  status: OrganizationStatus;
}

export const StatusHistoryTable: FC<StatusHistoryTableProps> = ({ status }) => {
  const { table, loading, currentPageItems } = useSellerHistoryTable({ status });
  return (
    <StyledCard>
      <LoadingSpinner loading={loading} text="Loading Seller Status History">
        {!isEmpty(currentPageItems) && (
          <>
            <StyledText size="bodyLarge">
              Seller Status History (<ItalicText>Top 100 Status</ItalicText>)
            </StyledText>
            <ScrollableContainer>
              <StyledTable>
                <TableHeader headerGroups={table.getHeaderGroups()} />
                <TableBody rowModel={table.getRowModel()} />
              </StyledTable>
            </ScrollableContainer>
          </>
        )}
      </LoadingSpinner>
    </StyledCard>
  );
};
