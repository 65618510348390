import { createColumnHelper, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { CreditBlockItem, SortFields } from "../CreditBlockItem";
import { CreditBlockListStatus } from "./CreditBlockStatus/CreditBlockListStatus";
import SortIcon from "../SortIcon";
import { DEFAULT_TOLERANCE_VALUE } from "components/finance/constants";

export const useCreditBlockTable = (
  currentPageItems: CreditBlockItem[],
  sort: SortFields,
  handleSort: (fieldName: string) => void
) => {
  const [activeSortColumn, setActiveSortColumn] = useState<string>(sort.fieldName);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CreditBlockItem>();
    return [
      columnHelper.accessor("sellerId", {
        header: "Seller ID",
      }),
      columnHelper.accessor("dueToleranceValuePast30Days", {
        header: () => (
          <div>
            Past Due Tolerance Amount
            <SortIcon
              sort={sort}
              handleSort={() => {
                handleSort("dueToleranceValuePast30Days");
                setActiveSortColumn("dueToleranceValuePast30Days");
              }}
              isActive={activeSortColumn === "dueToleranceValuePast30Days"}
            />
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return value ? value : DEFAULT_TOLERANCE_VALUE;
        },
      }),
      columnHelper.accessor("totalBalance", {
        header: () => (
          <div>
            Total Balance
            <SortIcon
              sort={sort}
              handleSort={() => {
                handleSort("totalBalance");
                setActiveSortColumn("totalBalance");
              }}
              isActive={activeSortColumn === "totalBalance"}
            />
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return value ?? 0;
        },
        enableSorting: true,
      }),
      columnHelper.accessor("balanceDue", {
        header: () => (
          <div>
            Past Due
            <SortIcon
              sort={sort}
              handleSort={() => {
                handleSort("balanceDue");
                setActiveSortColumn("balanceDue");
              }}
              isActive={activeSortColumn === "balanceDue"}
            />
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return value ?? 0;
        },
      }),
      columnHelper.accessor("balanceDuePast30Days", {
        header: () => (
          <div>
            30+ Days Past Due
            <SortIcon
              sort={sort}
              handleSort={() => {
                handleSort("balanceDuePast30Days");
                setActiveSortColumn("balanceDuePast30Days");
              }}
              isActive={activeSortColumn === "balanceDuePast30Days"}
            />
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return value ?? 0;
        },
      }),
      columnHelper.accessor("status", {
        header: "Account Status",
        cell: (info) => <CreditBlockListStatus status={info.getValue()} />,
      }),
      columnHelper.accessor("statusReason", {
        header: "Status Reason",
        cell: (info) => {
          const value = info.getValue();
          return value ?? "...";
        },
      }),
    ];
  }, [sort, handleSort, activeSortColumn]);

  const table = useReactTable({
    data: currentPageItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => String(row.sellerId),
  });

  return {
    table,
  };
};
