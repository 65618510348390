import { useSearchParams } from "react-router-dom";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { IssueStatus, IssueType } from "@deliverr/non-compliance-client";
import { useMount } from "react-use";
import { IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { getDamagedProductDetails } from "./getDamagedProductDetails";
import { getUnknownBarcodeDetails } from "./getUnknownBarcodeDetails";
import { getMissingBarcodeDetails } from "./getMissingBarcodeDetails";
import { getIssueOtherDetails } from "./getIssueOtherDetails";
import { getUnexpectedSkuDetails } from "./getUnexpectedSkuDetails";
import { getOverReceivedSkuDetails } from "./getOverReceivedSkuDetails";
import { getIllegibleExpirationDetails } from "./getIllegibleExpirationDetails";
import { getIllegibleLotDetails } from "./getIllegibleLotDetails";
import { getMissingContainerLabelDetails } from "./getMissingContainerLabelDetails";
import { handleNonComplianceError } from "utils/handleNonComplianceError";
import { getShortShipDetails } from "./getShortShipDetails";
import { getExpiredProductDetails } from "./getExpiredProductDetails";
import { getMismatchedDimensionsDetails } from "./getMismatchedDimensionsDetails";
import { getUnderReceiveDetails } from "./getUnderReceiveDetails";

type FormValues = {
  issueId: string;
};

export const useIssueActionTool = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit } = useForm<FormValues>({});
  const { nonComplianceClient } = useClientsWithAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [issueDetails, setIssueDetails] = useState<IssueSpecificDetailsProps | null>(null);
  const [actionProps, setActionProps] = useState<Partial<ActionsProps> | null>(null);

  useMount(async () => {
    if (searchParams.get("issueId")) {
      await getIssueById(searchParams.get("issueId")!);
    }
  });

  const issueId = useController({
    name: "issueId",
    defaultValue: searchParams.get("issueId") || "",
    control,
    rules: {
      required: true,
    },
  });

  const searchForIssue: SubmitHandler<FormValues> = async (data) => {
    setSearchParams({ issueId: data.issueId });
    await getIssueById(data.issueId);
  };

  const updateIssueActionPage = (
    newActionProps: Partial<ActionsProps> | Omit<ActionsProps, "isLoading" | "cancelIssue"> | null,
    issueDetails: IssueSpecificDetailsProps
  ) => {
    setIssueDetails(issueDetails);
    if (newActionProps) {
      const cancelIssue = async (reason: string, reasonDesc?: string | null) => {
        setIsLoading(true);
        try {
          await nonComplianceClient.cancelIssue({
            issueId: issueDetails.issue.id,
            reason: reason,
            reasonDesc: reasonDesc,
          });
          await getIssueById(issueDetails.issue.id);
          setIsLoading(false);
          toast.success("Issue cancellation completed", { position: "top-right" });
          return;
        } catch (error) {
          setIsLoading(false);
          handleNonComplianceError({ fn: "useIssueActionTool.cancelIssue" }, error as Error);
        }
      };

      const showCancellationAction = !([IssueStatus.RESOLVED, IssueStatus.CANCELLED] as IssueStatus[]).includes(
        issueDetails.issue.status
      );
      setActionProps({ ...newActionProps, cancelIssue: showCancellationAction ? cancelIssue : null });
    } else {
      setActionProps(null);
    }
  };

  const getIssueById = async (issueId: string) => {
    setIsLoading(true);
    const refreshIssue = async () => {
      await getIssueById(issueId);
    };
    try {
      const issueData = await nonComplianceClient.getIssueById({ issueId: issueId });
      // On a per issue type basis, we can add more details and actions
      switch (issueData.issueType) {
        case IssueType.DAMAGED_PRODUCT: {
          const { issueDetails, actionProps } = await getDamagedProductDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.UNKNOWN_BARCODE: {
          const { issueDetails, actionProps } = await getUnknownBarcodeDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.MISSING_BARCODE: {
          const { issueDetails, actionProps } = await getMissingBarcodeDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.UNEXPECTED_SKU: {
          const { issueDetails } = await getUnexpectedSkuDetails({
            issue: issueData,
            client: nonComplianceClient,
          });
          updateIssueActionPage(null, issueDetails);
          break;
        }
        case IssueType.OVER_RECEIVE: {
          const { issueDetails } = await getOverReceivedSkuDetails({
            issue: issueData,
            client: nonComplianceClient,
          });
          updateIssueActionPage(null, issueDetails);
          break;
        }
        case IssueType.OTHER: {
          const { issueDetails, actionProps } = await getIssueOtherDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.ILLEGIBLE_EXPIRATION: {
          const { issueDetails, actionProps } = await getIllegibleExpirationDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.ILLEGIBLE_LOT: {
          const { issueDetails, actionProps } = await getIllegibleLotDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.MISSING_CONTAINER_LABEL: {
          const { issueDetails, actionProps } = await getMissingContainerLabelDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.SHORT_SHIP: {
          const { issueDetails, actionProps } = await getShortShipDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.EXPIRED_PRODUCT: {
          const { issueDetails, actionProps } = await getExpiredProductDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionProps, issueDetails);
          break;
        }
        case IssueType.MISMATCHED_DIMENSIONS: {
          const { issueDetails, actionsProps } = await getMismatchedDimensionsDetails({
            issue: issueData,
            client: nonComplianceClient,
            refreshIssue,
            setIsLoading,
          });
          updateIssueActionPage(actionsProps, issueDetails);
          break;
        }
        case IssueType.UNDER_RECEIVE: {
          const { issueDetails } = await getUnderReceiveDetails({
            issue: issueData,
            client: nonComplianceClient,
          });
          updateIssueActionPage(null, issueDetails);
          break;
        }
        default:
          toast.error("Issue type not supported, contact engineering");
          break;
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "useIssueActionTool.getIssueById" }, error as Error);
    }
  };

  return {
    issueId,
    isLoading,
    searchForIssue: handleSubmit(searchForIssue),
    issueDetails,
    actionProps,
  };
};
