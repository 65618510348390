import { AttributionResults } from "@deliverr/inbound-client/lib/models/ReceiveAttribution";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useOnFormValueChange } from "hooks/useOnFormValueChange";
import _ from "lodash";
import { useState } from "react";
import { useForm, useController, SubmitHandler, useFieldArray } from "react-hook-form";
import { logError } from "utils/logger";

type FormValues = {
  eventId: string;
  boxAssignments: {
    boxLabel: string;
    qty: number;
  }[];
};

export const useBoxAssignmentTool = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewResults, setPreviewResults] = useState<AttributionResults>();
  const { control, handleSubmit, reset, getValues, watch } = useForm<FormValues>({
    defaultValues: {
      eventId: "",
      boxAssignments: [
        {
          boxLabel: "",
          qty: 0,
        },
      ],
    },
  });
  useOnFormValueChange(watch, () => setPreviewResults(undefined));

  const {
    fields: boxAssignmentFields,
    append,
    remove,
  } = useFieldArray({
    name: "boxAssignments",
    control,
    rules: {
      required: true,
      minLength: 1,
    },
  });

  const { inboundClient } = useClientsWithAuth();

  const eventIdController = useController({
    name: "eventId",
    control,
    rules: {
      required: true,
    },
    defaultValue: "",
  });

  function prepareBoxAssignmentsForSubmission(assignments: FormValues["boxAssignments"]) {
    return assignments.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.boxLabel.trim().toUpperCase()]: Number(curr.qty),
      }),
      {}
    );
  }

  const onSubmitPreview: SubmitHandler<FormValues> = async (data) => {
    try {
      const submissionValues = getValues();
      if (!submissionValues.eventId) {
        toast.error("Event Id is required");
        return;
      }
      const groupedByBoxLabel = _.groupBy(submissionValues.boxAssignments, (boxAssignment) => boxAssignment.boxLabel);
      const duplicates = _.filter(groupedByBoxLabel, (group) => group.length > 1);

      if (duplicates.length > 0) {
        toast.error("Duplicate box labels are not allowed");
        return;
      }
      setIsSubmitting(true);
      const previewResults = await inboundClient.previewBoxAssignmentAttribution({
        warehouseEventId: submissionValues.eventId,
        boxAssignments: prepareBoxAssignmentsForSubmission(submissionValues.boxAssignments),
      });
      setIsSubmitting(false);
      if (previewResults.error) {
        if (previewResults.error.message) {
          toast.error(previewResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }

      setPreviewResults(previewResults.data);
      toast.success("Preview successful", { position: "top-right" });
    } catch (error: any) {
      setIsSubmitting(false);
      // Validation errors are not returned like the regular receiving errors that
      // show up in the try block under previewResults.error
      if (error?.response?.data?.error?.payload) {
        toast.error(error.response.data.error.payload, { position: "top-right" });
        return;
      }
      logError({ fn: "useReceiveAttribution.onSubmitPreview" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  function resetAll() {
    reset();
    setPreviewResults(undefined);
    setIsSubmitting(false);
  }

  async function applyBoxAssignment() {
    try {
      const submissionValues = getValues();
      if (!submissionValues.eventId) {
        toast.error("Event Id is required");
        return;
      }
      const groupedByBoxLabel = _.groupBy(submissionValues.boxAssignments, (boxAssignment) => boxAssignment.boxLabel);
      const duplicates = _.filter(groupedByBoxLabel, (group) => group.length > 1);

      if (duplicates.length > 0) {
        toast.error("Duplicate box labels are not allowed");
        return;
      }
      setIsSubmitting(true);
      const persistResults = await inboundClient.persistBoxAssignmentAttribution({
        warehouseEventId: submissionValues.eventId,
        boxAssignments: prepareBoxAssignmentsForSubmission(submissionValues.boxAssignments),
      });
      setIsSubmitting(false);
      if (persistResults.error) {
        if (persistResults.error.message) {
          toast.error(persistResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
        return;
      }
      toast.success("Attribution committed successfully", { position: "top-right" });
      resetAll();
    } catch (error: any) {
      setIsSubmitting(false);
      logError({ fn: "useReceiveAttribution.applyAsnAssignment" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
    }
  }

  return {
    onSubmitPreview: handleSubmit(onSubmitPreview),
    eventId: eventIdController,
    boxAssignmentFields,
    previewResults,
    resetAll,
    applyAsnAssignment: applyBoxAssignment,
    control,
    append,
    remove,
    isSubmitting,
  };
};
