import { createColumnHelper } from "@tanstack/react-table";
import { InboundListViewEntry } from "../useInboundListView";
import { Table } from "components/Table/Table";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";

const StyledLink = styled(Link)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.BLUE[300]};
    &:hover {
      color: ${theme.colors.BLUE[500]};
      text-decoration: underline;
    }
  `
);

const columnHelper = createColumnHelper<InboundListViewEntry>();

const columns = [
  columnHelper.accessor("asnLabel", {
    id: "asnLabel",
    header: "PO",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("shippingPlanId", {
    id: "shippingPlanId",
    header: "Shipping Plan",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("shipmentId", {
    id: "shipmentId",
    header: "Shipment",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("facilityId", {
    id: "facilityId",
    header: "Facility",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("sellerId", {
    id: "seller",
    header: "Seller",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("cartonsExpected", {
    id: "cartonsExpected",
    header: "Expected",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("cartonsPendingScan", {
    id: "cartonsPendingScan",
    header: "Pending Scan",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("cartonsScanned", {
    id: "cartonsScanned",
    header: "Scanned",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("cartonsReconciled", {
    id: "cartonsReconciled",
    header: "Reconciled",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("hasSlaViolation", {
    id: "hasSlaViolation",
    header: "SLA Violation",
    cell: (info) => (info.getValue() ? "Yes" : "No"),
  }),
  columnHelper.accessor("createdAt", {
    id: "created",
    header: "Created",
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    sortingFn: "datetime",
  }),
  columnHelper.accessor("shipmentId", {
    id: "drilldown", // Add this unique ID
    header: "Drilldown",
    cell: (info) => (
      <StyledLink to={`/inbound-visibility/forwarding-inbound/${info.getValue()}`}>View Details</StyledLink>
    ),
  }),
];

export function InboundListTable({ data }: { data: InboundListViewEntry[] }) {
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        showSearch={true}
        excludeFromSearch={["cartonsExpected", "cartonsScanned", "cartonsPendingScan", "cartonsReconciled"]}
      />
    </div>
  );
}
