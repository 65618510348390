import { useForm, useController, SubmitHandler } from "react-hook-form";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { toast } from "@deliverr/ui";
import { logError } from "utils/logger";
import { useState } from "react";
import { ExpandedZoneAssignment } from "@deliverr/inbound-client";
import { ZoneManagementInputRules } from "../InputPatterns";

type ViewMerchantZoneAssignmentFormValues = {
  facilityId: string;
  merchantId: string;
};

export const useViewMerchantZoneAssignments = () => {
  const { control, handleSubmit, reset, setError } = useForm<ViewMerchantZoneAssignmentFormValues>({});
  const [zoneAsssignmentsData, setZoneAssignmentsData] = useState<ExpandedZoneAssignment[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentFacilityId, setCurrentFacilityId] = useState<string>("");
  const [currentMerchantId, setCurrentMerchantId] = useState<string>("");
  const { inboundClient } = useClientsWithAuth();

  const { facilityIdInput, merchantIdInput } = ZoneManagementInputRules;

  const defaultInputValues = {
    facilityId: "",
    merchantId: "",
  };

  const facilityIdInputController = useController({
    name: "facilityId",
    control,
    rules: {
      pattern: { value: facilityIdInput.pattern, message: facilityIdInput.message },
    },
    defaultValue: defaultInputValues.facilityId,
  });

  const merchantIdInputController = useController({
    name: "merchantId",
    control,
    rules: {
      pattern: {
        value: merchantIdInput.pattern,
        message: merchantIdInput.message,
      },
    },
    defaultValue: defaultInputValues.merchantId,
  });

  const setCurrentFacilityAndMerchantId = (facilityId: string, merchantId: string) => {
    setCurrentFacilityId(facilityId);
    setCurrentMerchantId(merchantId);
  };

  const resetInputsAndState = () => {
    setCurrentFacilityAndMerchantId("", "");
    setZoneAssignmentsData([]);
    reset(defaultInputValues);
  };

  const viewMerchantZoneAssignments: SubmitHandler<ViewMerchantZoneAssignmentFormValues> = async (data) => {
    try {
      const { facilityId, merchantId } = data;

      //we need to do this conversion because the API expects undefined, not empty string
      const optionalFacilityId = facilityId ? facilityId : undefined;
      const optionalMerchantId = merchantId ? merchantId : undefined;

      if (!optionalFacilityId && !optionalMerchantId) {
        setError("merchantId", { type: "manual", message: "You must submit at least a facility ID or a merchant ID" });
        return;
      }

      setIsSubmitting(true);
      const response = await inboundClient.listZoneAssignments(optionalFacilityId, optionalMerchantId);
      setIsSubmitting(false);

      if (response.error) {
        resetInputsAndState();
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      const zoneAssignmentData = response.data?.zoneAssignments ?? [];
      if (zoneAssignmentData.length === 0) {
        toast.info(`No zone assignments found`, { position: "top-right" });
      }
      setZoneAssignmentsData(zoneAssignmentData);
      setCurrentFacilityAndMerchantId(facilityId, merchantId);
      reset(defaultInputValues);
    } catch (error) {
      setIsSubmitting(false);
      resetInputsAndState();
      logError({ fn: "useViewMerchantZoneAssignments.viewMerchantZoneAssignments" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  const unassignMerchantFromZone = async (zoneAssignmentId: number, zoneName: string, merchantId: string) => {
    toast.dismiss();
    setIsSubmitting(true);
    try {
      const response = await inboundClient.deactivateZoneAssignments([zoneAssignmentId]);
      setIsSubmitting(false);
      if (response.error) {
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      toast.success(`Unassigned ${merchantId} from zone ${zoneName}`, { position: "top-right" });
      //requery the zone assignments to update the table
      viewMerchantZoneAssignments({ facilityId: currentFacilityId, merchantId: currentMerchantId });
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useViewMerchantZoneAssignments.unassignMerchantFromZone" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  return {
    facilityIdInputController,
    merchantIdInputController,
    zoneAsssignmentsData,
    onSubmit: handleSubmit(viewMerchantZoneAssignments),
    unassignMerchantFromZone,
    isSubmitting,
  };
};
